const STAGE = {
  local: {
    url: 'http://localhost:8000',
  },
  homologation: {
    url: 'https://api.cativa.hom.leaderaplicativos.com.br',
  },
  production: {
    url: 'https://api.cativa.hom.leaderaplicativos.com.br',
  },
};

export const API_URL = `${STAGE.production.url}/api/v1`;

export const BROWSER_LANGUAGE = window.navigator.language;
