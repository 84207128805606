import React from 'react';

import IntlMessages from '../helpers/IntlMessages';
import { adminRoot } from './defaultValues';

const data = [
  {
    id: 'records',
    icon: 'recordsIcon',
    label: <IntlMessages id='screens.register' />,
    sub_title: <IntlMessages id='screens.register' />,
    to: `${adminRoot}/records`,
    subs: [
      {
        icon: 'iconsminds-forward',
        label: 'screens.list.products',
        to: `${adminRoot}/records/products`,
      },
      {
        icon: 'iconsminds-forward',
        label: 'screens.list.multibrands',
        to: `${adminRoot}/records/multibrands`,
      },
      {
        icon: 'iconsminds-forward',
        label: 'screens.list.brands',
        to: `${adminRoot}/records/brands`,
      },
      {
        icon: 'iconsminds-forward',
        label: 'screens.list.collections',
        to: `${adminRoot}/records/collections`,
      },
      {
        icon: 'iconsminds-forward',
        label: 'screens.register.contents',
        to: `${adminRoot}/records/contents`,
      },
      {
        icon: 'iconsminds-forward',
        label: 'screens.register.coupons',
        to: `${adminRoot}/records/coupons`,
      },
    ],
  },
  {
    id: 'reports',
    icon: 'reportsIcon',
    label: <IntlMessages id='screens.report' />,
    sub_title: <IntlMessages id='screens.report' />,
    to: `${adminRoot}/reports`,
    subs: [
      {
        icon: 'iconsminds-forward',
        label: 'screens.report.indicators',
        to: `${adminRoot}/reports/indicators`,
      },
      {
        icon: 'iconsminds-forward',
        label: 'screens.report.sales',
        to: `${adminRoot}/reports/sales`,
      },
      {
        icon: 'iconsminds-forward',
        label: 'screens.report.sellers',
        to: `${adminRoot}/reports/sellers`,
      },
      {
        icon: 'iconsminds-forward',
        label: 'screens.report.coupons',
        to: `${adminRoot}/reports/coupons`,
      },
    ],
  },
  {
    id: 'settings',
    icon: 'configurationsIcon',
    label: <IntlMessages id='screens.config' />,
    sub_title: <IntlMessages id='screens.config' />,
    to: `${adminRoot}/settings`,
    subs: [
      {
        icon: 'iconsminds-forward',
        label: 'screens.generalConfig',
        to: `${adminRoot}/records/settings`,
      },
      {
        icon: 'iconsminds-forward',
        label: 'screens.register.scorescales',
        to: `${adminRoot}/records/score-scales`,
      },
      {
        icon: 'iconsminds-forward',
        label: 'screens.register.scorebrands',
        to: `${adminRoot}/records/score-brands`,
      },
    ],
  },
];

export default data;
